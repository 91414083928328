<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float:left">
        <el-button type="primary" icon="el-icon-plus" @click="handler_add()" v-if="addflag">添加</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="客户名称" v-model="customerName" class="input-with-select" style="width:250px;"></el-input>&nbsp;
        <el-select v-model="status" placeholder="请选择跟进状态" style="margin-right: 5px;">
          <el-option v-for="item in statuses" :key="item" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="handler_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>

    <div>
      <el-table
        :data="page.list"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        border
        width="width:100%; height:50%"
      >
        <el-table-column type="index" label="顺序" width="50"></el-table-column>
        <el-table-column
          prop="customerName"
          label="客户名称"
          width="200"
        ></el-table-column>

        <el-table-column prop="target" label="跟进目标"> </el-table-column>
        <el-table-column
          prop="type"
          label="跟进类型"
          width="100"
          :formatter="formatType"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="跟进状态"
          width="60"
          :formatter="formatStatus"
        ></el-table-column>
        <el-table-column
          prop="starttime"
          label="开始跟进时间"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="endtime"
          label="结束跟进时间"
          width="160"
        ></el-table-column>
        <el-table-column label="操作" conter width="150">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="edit(scope.row)"
              v-show="scope.row.status == 1"
              >编辑
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="follow(scope.row)"
              >跟进
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import FOLLOW from "../../api/biz/api_followplan";
import syscomm from "../../utils/syscomm";
import COMM from "../../api/base/api_comm";

export default {
  name: "followlist", // 跟进计划列表
  props: {
    customerInfo: {
      type: Object
    },
    addflag:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      customerName: "",
      status: "-1",
      showLoading: false,
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      statuses: [
        { name: "全部", value: "-1" },
        { name: "可用", value: 1 },
        { name: "关闭", value: 0 },
      ],
      auth: {}
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {},
  // 方法定义
  methods: {
    init() {
      this.auth=this.$getAuth('/followList');
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      let customerid = null;
      if (this.customerInfo != null) {
        customerid = this.customerInfo.id;
      }

      // FOLLOW.listWithCustomer({
      FOLLOW.list({
        pageNum: this.page?.pageNum,
        pageSize: this.page?.pageSize,
        custid: customerid,
        keyword: this.customerName,
        // customerName: this.customerName,
        status: this.status === "-1" ? null : this.status,
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res?.pageData;
          this.page.total = data?.total;
          this.page.list = data?.list;
        } else {
          this.$message.error(res?.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    handler_add() {
      let customerid = null;
      if (this.customerInfo != null) {
        customerid = this.customerInfo.id
      }
      this.$router.push({ path: '../followEdit', query: { customerId: customerid } });
    },
    follow(row) {
      this.$router.push({
        path: "../followPlanRecord",
        query: {
          followPlanId: row?.id,
        },
      });
    },
    edit(row) {
      this.$router.push({
        path: "../followEdit",
        query: {
          followPlanId: row?.id,
        },
      });
    },

    formatType(row, coloumn) {
      let type = row?.type;
      return type === 0
        ? "新客开拓"
        : type === 1
        ? "创建年度验证计划"
        : "未知";
    },
    formatStatus(row, column) {
      let status = row?.status;
      return status === 1 ? "可用" : status === 0 ? "关闭" : "未知";
    },
    handler_search() {
      this.initPageData();
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}
.search_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search_buttons input {
  margin-right: 20px;
}
.search_buttons input:last-child {
  margin-right: 0;
}
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px;
  /*padding: 5px 10px;*/
}

.red {
  color: #ff0000;
}
</style>